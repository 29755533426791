import { useIsMobile } from '../../Utils/Breakpoint.hooks';
import { BottomSheet, BottomSheetProps } from '../BottomSheet/BottomSheet';
import { Popup, PopupProps } from '../Popup/Popup';

export type ResponsiveModalProps = PopupProps & BottomSheetProps;

export const ResponsiveModal = ({
  children,
  ...props
}: ResponsiveModalProps) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <BottomSheet {...props}>{children}</BottomSheet>
  ) : (
    <Popup {...props}>{children}</Popup>
  );
};
