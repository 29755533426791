import { format } from 'date-fns';
import { useMemo } from 'react';
import { Empty } from '../Empty/Empty';
import { useDateRangeSearchParams } from '../Form/DateRange/DateRange.hook';
import { MapListLayout } from '../Layout/MapList/MapListLayout';
import { Loading } from '../Loading/Loading';
import { useBoundsSearchParams } from '../Map/Map.hooks';
import { MatchPost } from '../Post/Match/MatchPost';
import { MatchModel, useMatchesQuery } from './Matches.hooks';

const groupMatchesByDate = (matches: MatchModel[]) => {
  return matches.reduce((acc, match) => {
    const date = format(match.startAt, 'L월 d일 (ccc)');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(match);
    return acc;
  }, {} as Record<string, MatchModel[]>);
};

const Matches = () => {
  const { fromDate, toDate } = useDateRangeSearchParams();
  const { minX, minY, maxX, maxY } = useBoundsSearchParams();
  const {
    data: matches = [],
    isSuccess,
    isLoading,
  } = useMatchesQuery({
    minX,
    minY,
    maxX,
    maxY,
    fromDate,
    toDate,
  });

  const markers = useMemo(
    () =>
      matches.map(({ stadium, completed }) => ({
        lat: stadium.lat,
        lng: stadium.lng,
        isOccupied: completed,
      })),
    [matches]
  );

  const groupedMatches = useMemo(() => groupMatchesByDate(matches), [matches]);

  return (
    <MapListLayout markers={markers}>
      {({ panToBounds }) => (
        <>
          {Object.entries(groupedMatches).map(([date, matches]) => (
            <div key={date} className="mx-3 my-4">
              <h5 className="mb-2 text-center">{date}</h5>
              {matches.map((match, i) => (
                <MatchPost
                  {...match}
                  onClick={({ stadium }: MatchModel) => {
                    const { lat, lng } = stadium;
                    panToBounds({ lat, lng });
                  }}
                  className="mb-3"
                  key={i}
                />
              ))}
            </div>
          ))}
          {isLoading && <Loading />}
          {isSuccess && matches.length == 0 && <Empty>터엉~~ 비었어요.</Empty>}
        </>
      )}
    </MapListLayout>
  );
};

export default Matches;
