import { ReactNode } from 'react';
import empty from './empty.png';

export type EmptyProps = {
  children: ReactNode;
};

export const Empty = ({ children }: EmptyProps) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={empty} className="" width={150} height={150} />
      {children}
    </div>
  );
};
