import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DateRangeType } from '../Form/DateRange/DateRange';
import { BoundsType } from '../Map/Map.hooks';
import { StadiumModel } from '../Stadium/Stadium.hooks';

declare global {
  interface Window {
    cachedMatches?: MatchesResponse;
  }
}

export type MatchesParams = BoundsType & DateRangeType;
export type MatchModel = {
  stadium: StadiumModel;
  startAt: Date;
  endAt: Date;
  teamName: string;
  contact: string;
  age: string;
  cost: string;
  uniformColor: string;
  completed: boolean;
  sourceUrl: string;
};
export type MatchesResponse = {
  matches: {
    stadium: {
      name: string;
      address: string;
      lat: string;
      lng: string;
    };
    start_at: string;
    end_at: string;
    team_name: string;
    contact: string;
    age: string;
    cost: string;
    uniform_color: string;
    completed: boolean;
    source_url: string;
  }[];
};
export const useMatchesQuery = (params: MatchesParams) => {
  return useQuery<MatchModel[]>({
    queryKey: ['matches', params],
    queryFn: async () => {
      // param으로 필터
      // startAt 시간값 이상함
      let data: MatchesResponse;
      if (window.cachedMatches) {
        data = window.cachedMatches;
      } else {
        const { data: original } = await axios.get<MatchesResponse>(
          '/api/v1/match/list'
        );
        window.cachedMatches = original;
        data = original;
      }

      return data.matches
        .map((match) => ({
          ...match,
          stadium: {
            ...match.stadium,
            lat: Number(match.stadium.lat),
            lng: Number(match.stadium.lng),
          },
          startAt: new Date(match.start_at),
          endAt: new Date(match.end_at),
          sourceUrl: match.source_url,
          teamName: match.team_name,
          uniformColor: match.uniform_color,
        }))
        .filter(({ startAt, stadium }) => {
          return (
            startAt <= params.toDate &&
            startAt >= params.fromDate &&
            stadium.lat >= params.minY &&
            stadium.lat <= params.maxY &&
            stadium.lng >= params.minX &&
            stadium.lng <= params.maxX
          );
        });
    },
  });
};
