import classNames from 'classnames';
import { format } from 'date-fns';
import { useCallback } from 'react';
import { PostClickHandler, PostModel } from './Post.hook';

export type PostProps = {
  className?: string;
  onClick?: PostClickHandler;
} & PostModel;

export const Post = ({ className, onClick, ...post }: PostProps) => {
  return (
    <div
      className={classNames('card', className, {
        ['opacity-25']: post.isOccupied,
      })}
      onClick={useCallback(() => {
        onClick?.(post);
      }, [onClick, post])}
    >
      <div className="card-body">
        <h6 className="card-subtitle mb-2 text-body-secondary">
          <small>
            {format(post.startedAt, 'p')} ~ {format(post.endedAt, 'p')}
          </small>
        </h6>
        <h5 className="card-title">
          {post.isOccupied && '[마감] '}
          {post.teamName}
        </h5>
        <p className="card-text">
          {post.stadium.name}{' '}
          {post.seeking
            .map(({ position, count }) => `${position} ${count}명`)
            .join(', ')}
        </p>
        <a href={post.source.url} target="_blank" className="card-link">
          모집글 보기
        </a>
      </div>
    </div>
  );
};
