import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="p-3 d-flex align-items-center justify-content-center h-100 flex-column">
      <h1>이런!</h1>
      <p>미안해요, 뭔가 잘못됐어요.</p>
      {error instanceof Response && (
        <p>
          <i>{error.statusText}</i>
        </p>
      )}
      {error instanceof Error && (
        <p>
          <i>{error.message}</i>
        </p>
      )}
    </div>
  );
}
