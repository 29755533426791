import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactNode, useCallback, useRef } from 'react';
import {
  formatString,
  useDateRangeSearchParams,
} from '../../Form/DateRange/DateRange.hook';
import {
  DateRangePicker,
  DateRangePickerProps,
} from '../../Form/DateRange/DateRangePicker';
import { Map } from '../../Map/Map';
import { MarkerModel, useBoundsSearchParams } from '../../Map/Map.hooks';
import { Realtime } from '../../Realtime/Realtime';
import { useMergeSearchParams } from '../../Utils/SearchParams.hooks';
import styles from './MapListLayout.module.css';

type PanToBoundsType = (args: { lat: number; lng: number }) => void;

type MapListLayoutChildren = ({
  panToBounds,
}: {
  panToBounds: PanToBoundsType;
}) => ReactNode;

export type MapListLayoutProps = {
  markers: MarkerModel[];
  children: MapListLayoutChildren;
};

export const MapListLayout = ({ markers, children }: MapListLayoutProps) => {
  const mapRef = useRef<naver.maps.Map>();
  const { fromDate, toDate } = useDateRangeSearchParams();
  const { minX, minY, maxX, maxY } = useBoundsSearchParams();
  const mergeSearchParams = useMergeSearchParams();

  const onIdle = useCallback(() => {
    const map = mapRef.current;
    if (!map) return;
    const bounds = map.getBounds();
    const min = bounds.getMin();
    const max = bounds.getMax();

    mergeSearchParams({
      minX: String(min.x),
      minY: String(min.y),
      maxX: String(max.x),
      maxY: String(max.y),
    });
  }, [mergeSearchParams]);

  const onDateRangeChange: DateRangePickerProps['onChange'] = useCallback(
    ({ from, to }) => {
      mergeSearchParams({
        from: format(from, formatString),
        to: format(to, formatString),
      });
    },
    [mergeSearchParams]
  );

  const panToBounds: PanToBoundsType = useCallback(({ lat, lng }) => {
    const map = mapRef.current;
    if (!map) return;
    const offset = 0.002;
    const sw = new naver.maps.LatLng(lat - offset, lng - offset);
    const ne = new naver.maps.LatLng(lat + offset, lng + offset);
    map.panToBounds(new naver.maps.LatLngBounds(sw, ne));
  }, []);

  return (
    <div className={classNames(styles.root, 'd-grid flex-fill')}>
      <Map
        ref={mapRef}
        markers={markers}
        minX={minX}
        minY={minY}
        maxX={maxX}
        maxY={maxY}
        className={styles.map}
        onIdle={onIdle}
        onMarkerClick={({ lat, lng }) => panToBounds({ lat, lng })}
      />
      <div
        className={classNames(
          styles.information,
          'overflow-auto bg-body-tertiary'
        )}
      >
        <div className="d-flex align-items-center justify-content-between p-3 fs-6 position-sticky top-0 z-1 bg-body-tertiary">
          <DateRangePicker
            className="me-1"
            fromDate={fromDate}
            toDate={toDate}
            onChange={onDateRangeChange}
          />
          <Realtime />
        </div>
        {children({ panToBounds })}
      </div>
    </div>
  );
};
