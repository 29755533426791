import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export type ModalPortalProps = { children: ReactNode };

export const ModalPortal = ({ children }: ModalPortalProps) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('modal-portal')!
  );
};
