import { format } from 'date-fns';
import { useMemo } from 'react';
import { Post } from '~/components/Post/Post';
import { Empty } from '../Empty/Empty';
import { useDateRangeSearchParams } from '../Form/DateRange/DateRange.hook';
import { MapListLayout } from '../Layout/MapList/MapListLayout';
import { Loading } from '../Loading/Loading';
import { useBoundsSearchParams } from '../Map/Map.hooks';
import { PostModel } from '../Post/Post.hook';
import { usePostsQuery } from '../Posts/Posts.hooks';

const groupPostsByDate = (posts: PostModel[]) => {
  return posts.reduce((acc, post) => {
    const date = format(post.startedAt, 'L월 d일 (ccc)');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(post);
    return acc;
  }, {} as Record<string, PostModel[]>);
};

const Guests = () => {
  const { fromDate, toDate } = useDateRangeSearchParams();
  const { minX, minY, maxX, maxY } = useBoundsSearchParams();
  const {
    data: posts = [],
    isSuccess,
    isLoading,
  } = usePostsQuery({
    minX,
    minY,
    maxX,
    maxY,
    fromDate,
    toDate,
  });

  const markers = useMemo(
    () =>
      posts.map((post) => ({
        lat: post.stadium.lat,
        lng: post.stadium.lng,
        isOccupied: post.isOccupied,
      })),
    [posts]
  );

  const groupedPosts = useMemo(() => groupPostsByDate(posts), [posts]);

  return (
    <MapListLayout markers={markers}>
      {({ panToBounds }) => (
        <>
          {Object.entries(groupedPosts).map(([date, posts]) => (
            <div key={date} className="mx-3 my-4">
              <h5 className="mb-2 text-center">{date}</h5>
              {posts.map((post, i) => (
                <Post
                  {...post}
                  onClick={({ stadium }: PostModel) => {
                    const { lat, lng } = stadium;
                    panToBounds({ lat, lng });
                  }}
                  className="mb-3"
                  key={i}
                />
              ))}
            </div>
          ))}
          {isLoading && <Loading />}
          {isSuccess && posts.length == 0 && <Empty>터엉~~ 비었어요.</Empty>}
        </>
      )}
    </MapListLayout>
  );
};

export default Guests;
