import classNames from 'classnames';
import { format } from 'date-fns';

export type DateRangeType = {
  fromDate: Date;
  toDate: Date;
};

export type DateRangeProps = {
  className?: string;
} & DateRangeType;

export const DateRange = ({ className, fromDate, toDate }: DateRangeProps) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-between',
        className
      )}
    >
      <i className="bi bi-calendar-date me-1" />
      <span className="ws-pre">
        {format(fromDate, 'L월 d일')} ~ {format(toDate, 'L월 d일')}
      </span>
    </div>
  );
};
