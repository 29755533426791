import { useSearchParams } from 'react-router-dom';
import createIcon from '../Marker/icon';

export type onIdleType = () => void;

export type BoundsType = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};

export type MarkerModel = {
  lat: number;
  lng: number;
  isOccupied: boolean;
};

export type MarkerClickHandler = (marker: MarkerModel) => void;

export const setMarkers = (
  map: naver.maps.Map,
  markers: MarkerModel[],
  onMarkerClick: MarkerClickHandler,
  markersMap: Map<string, naver.maps.Marker>,
  markerHandlersMap: Map<string, naver.maps.MapEventListener>
) => {
  for (const marker of markers) {
    const id = `${marker.lat}-${marker.lng}`;
    if (markersMap.has(id)) {
      continue;
    }

    const mapMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(marker.lat, marker.lng),
      map: map,
      icon: createIcon(marker.isOccupied ? { opacity: 0.25 } : {}),
    });

    markerHandlersMap.set(
      id,
      naver.maps.Event.addListener(mapMarker, 'click', function () {
        onMarkerClick(marker);
      })
    );
    markersMap.set(id, mapMarker);
  }
};

export const removeMarkers = (
  map: naver.maps.Map,
  markersMap: Map<string, naver.maps.Marker>,
  markerHandlersMap: Map<string, naver.maps.MapEventListener>
) => {
  const bounds = map.getBounds();

  for (const [postId, marker] of markersMap) {
    const position = marker.getPosition();

    if (!bounds.hasPoint(position)) {
      marker.setMap(null);
      markersMap.delete(postId);
      markerHandlersMap.delete(postId);
    }
  }
};

export const useBoundsSearchParams = () => {
  const [searchParams] = useSearchParams();

  const minX = Number(searchParams.get('minX')) || 126.72321700432013;
  const minY = Number(searchParams.get('minY')) || 37.156062492035986;
  const maxX = Number(searchParams.get('maxX')) || 127.26715194703995;
  const maxY = Number(searchParams.get('maxY')) || 37.758103493037474;

  return {
    minX,
    minY,
    maxX,
    maxY,
  };
};
