import classNames from 'classnames';
import styles from './Realtime.module.css';

export type RealtimeProps = {
  className?: string;
};

export const Realtime = ({ className }: RealtimeProps) => {
  return (
    <div className={classNames(styles.root, className)}>
      <span className={classNames(styles.dot, 'mx-1')}></span>
      <span>실시간</span>
    </div>
  );
};
