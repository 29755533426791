import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export const MODAL_TRANSITION_TIMEOUT = 500;

export type ModalTransitionProps = {
  children: ReactNode;
} & Pick<CSSTransitionProps, 'in'>;

export const ModalTransition = ({
  children,
  ...props
}: ModalTransitionProps) => {
  const ref = useRef(null);
  return (
    <CSSTransition
      {...props}
      timeout={{
        enter: MODAL_TRANSITION_TIMEOUT,
        exit: MODAL_TRANSITION_TIMEOUT,
      }}
      nodeRef={ref}
      unmountOnExit
      classNames="modal-transition"
    >
      <div ref={ref}>{children}</div>
    </CSSTransition>
  );
};
