import { setDefaultOptions } from 'date-fns';
import { ko } from 'date-fns/locale';
import { Outlet } from 'react-router-dom';
import { NavBar } from '~/components/NavBar/NavBar';

setDefaultOptions({ locale: ko });

function App() {
  return (
    <div className="d-flex flex-column h-100">
      <NavBar />
      <Outlet />
    </div>
  );
}

export default App;
