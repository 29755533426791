import { useQuery } from '@tanstack/react-query';
import { DateRangeType } from '../Form/DateRange/DateRange';
import { BoundsType } from '../Map/Map.hooks';
import { PostModel } from '../Post/Post.hook';
import data from './data.json';

export type PostsParams = BoundsType & DateRangeType;
export const usePostsQuery = (params: PostsParams) => {
  return useQuery<PostModel[]>({
    queryKey: ['posts', params],
    queryFn: () => {
      return data
        .map((post) => ({
          ...post,
          startedAt: new Date(post.startedAt),
          endedAt: new Date(post.endedAt),
        }))
        .filter(({ startedAt, stadium }) => {
          return (
            startedAt <= params.toDate &&
            startedAt >= params.fromDate &&
            stadium.lat >= params.minY &&
            stadium.lat <= params.maxY &&
            stadium.lng >= params.minX &&
            stadium.lng <= params.maxX
          );
        }) as unknown as PostModel[];
    },
  });
};
