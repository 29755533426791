import classNames from 'classnames';
import styles from './Switch.module.css';
import { ComponentProps, ReactNode } from 'react';

export type SwitchProps = {
  id: string;
  children: ReactNode;
  className?: string;
} & Pick<ComponentProps<'input'>, 'checked' | 'onChange'>;

export const Switch = ({ id, children, className, ...props }: SwitchProps) => {
  return (
    <div
      className={classNames(
        styles.root,
        'form-check form-switch mb-0',
        className
      )}
    >
      <input
        className="form-check-input"
        {...props}
        type="checkbox"
        role="switch"
        id={id}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};
