import { useSearchParams } from 'react-router-dom';
import { addDays, parse } from 'date-fns';

const now = new Date();
export const formatString = 'yyyy-MM-dd';

export const useDateRangeSearchParams = () => {
  const [searchParams] = useSearchParams();

  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const fromDate = from ? parse(from, formatString, now) : now;
  const toDate = to ? parse(to, formatString, now) : addDays(now, 6);

  return {
    fromDate,
    toDate,
  };
};
