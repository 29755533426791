import { useNavigate } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { useCallback } from 'react';

export const useMergeSearchParams = () => {
  const navigate = useNavigate();

  return useCallback(
    (params: Record<string, string>) => {
      navigate(
        {
          search: stringify({
            ...parse(window.location.search, { ignoreQueryPrefix: true }),
            ...params,
          }),
        },
        { replace: true }
      );
    },
    [navigate]
  );
};
