import classNames from 'classnames';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { MatchModel } from '../../Matches/Matches.hooks';

export type MatchPostProps = {
  className?: string;
  onClick?: (match: MatchModel) => void;
} & MatchModel;

export const MatchPost = ({ className, onClick, ...match }: MatchPostProps) => {
  const texts = useMemo(
    () =>
      [
        match.age,
        match.cost ? `비용: ${match.cost}` : false,
        match.uniformColor ? `유니폼: ${match.uniformColor}` : false,
      ].filter((x) => !!x),
    [match.age, match.cost, match.uniformColor]
  );
  return (
    <div
      className={classNames('card', className, {
        ['opacity-25']: match.completed,
      })}
      onClick={useCallback(() => {
        onClick?.(match);
      }, [onClick, match])}
    >
      <div className="card-body">
        <h6 className="card-subtitle mb-2 text-body-secondary">
          <small>
            {format(match.startAt, 'p')} ~ {format(match.endAt, 'p')}
          </small>
        </h6>
        <h5 className="card-title">
          {match.completed && '[마감] '}
          {match.teamName || '팀명N/A'}
          {' · '}
          {match.stadium.name}
        </h5>
        <p className="card-text">{texts.join(' · ')}</p>
        <a href={match.sourceUrl} target="_blank" className="card-link">
          모집글 보기
        </a>
      </div>
    </div>
  );
};
