import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { ko } from 'date-fns/locale';
import { useCallback, useState } from 'react';
import { DateRange as DateRangeType, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { ModalHeader } from '../../Modals/Header/ModalHeader';
import { ModalPortal } from '../../Modals/ModalPortal';
import { ModalTransition } from '../../Modals/ModalTransition';
import { ResponsiveModal } from '../../Modals/Responsive/ResponsiveModal';
import { Switch } from '../Switch/Switch';
import { DateRange, DateRangeProps } from './DateRange';
import styles from './DateRangePicker.module.css';

const noop = () => {};

export type DateRangePickerProps = {
  onChange: ({ from, to }: { from: Date; to: Date }) => void;
} & DateRangeProps;

export const DateRangePicker = ({
  onChange,
  ...props
}: DateRangePickerProps) => {
  const [range, setRange] = useState<DateRangeType | undefined>({
    from: props.fromDate,
    to: props.toDate,
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onDateClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const refCallback = useCallback((el: HTMLDivElement) => {
    if (el) {
      disableBodyScroll(el);
    } else {
      clearAllBodyScrollLocks();
    }
  }, []);

  const onSubmit = useCallback(() => {
    const { from, to } = range || {};
    if (from && to) {
      onCloseModal();
      onChange({ from, to });
    }
  }, [onChange, onCloseModal, range]);

  return (
    <div>
      <button className="bg-transparent border-0 p-0 m-0" onClick={onDateClick}>
        <DateRange {...props} />
      </button>
      <ModalPortal>
        <ModalTransition in={isModalOpen}>
          <ResponsiveModal
            className={styles.modal}
            onBackdropClick={onCloseModal}
            onCloseButtonClick={onCloseModal}
          >
            <div className="d-flex flex-column h-100">
              <ModalHeader>기간 선택</ModalHeader>
              <div ref={refCallback} className="flex-fill overflow-auto p-3">
                <DayPicker
                  mode="range"
                  max={30}
                  locale={ko}
                  selected={range}
                  onSelect={setRange}
                />
                <div className="d-flex my-3 justify-content-end">
                  <Switch
                    id="occupied"
                    children="마감포함"
                    checked
                    onChange={noop}
                  />
                </div>
              </div>
              <div className="p-3 d-flex justify-content-space-between">
                <button
                  className="w-50 me-1 btn btn-lg btn-outline-secondary"
                  onClick={onCloseModal}
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="w-50 ms-1 btn btn-lg btn-primary"
                  disabled={!range}
                  onClick={onSubmit}
                >
                  적용하기
                </button>
              </div>
            </div>
          </ResponsiveModal>
        </ModalTransition>
      </ModalPortal>
    </div>
  );
};
