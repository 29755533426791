import { useCallback, useEffect, useState } from 'react';

const mediaQuery = window.matchMedia('(max-width: 991px)');

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean>(!!mediaQuery.matches);
  const handleMediaChange: (e: MediaQueryListEvent) => void = useCallback(
    (e) => {
      if (e.matches) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    },
    []
  );

  useEffect(() => {
    mediaQuery.addEventListener('change', handleMediaChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, [handleMediaChange]);

  return isMobile;
};
