import { QueryClientProvider } from '@tanstack/react-query';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import App from '~/components/App.tsx';
import ErrorPage from '~/components/ErrorPage/ErrorPage.tsx';
import Guests from '~/components/Guests/Guests.tsx';
import Matches from '~/components/Matches/Matches.tsx';
import { queryClient } from '~/components/QueryClient/QueryClient.hooks.ts';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Navigate to="matches" replace /> },
      {
        path: 'matches',
        element: <Matches />,
      },
      {
        path: 'guests',
        element: <Guests />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);
