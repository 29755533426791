import url from './ball.png';
const createIcon = (imageStyle: Record<string, unknown>) => ({
  content: `<img
    src="${url}"
    width="30"
    height="30"
    style="${Object.entries(imageStyle)
      .map(([key, value]) => `${key}:${value};`)
      .join('')}">
  `,
  size: new naver.maps.Size(860, 860),
  scaledSize: new naver.maps.Size(30, 30),
  origin: new naver.maps.Point(0, 0),
  anchor: new naver.maps.Point(15, 15),
});
export default createIcon;
