import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  BoundsType,
  MarkerClickHandler,
  MarkerModel,
  onIdleType,
  removeMarkers,
  setMarkers,
} from './Map.hooks';

type MapProps = {
  className?: string;
  markers: MarkerModel[];
  onIdle: onIdleType;
  onMarkerClick: MarkerClickHandler;
} & BoundsType;

export const Map = forwardRef<naver.maps.Map | undefined, MapProps>(
  ({ className, markers, onIdle, onMarkerClick, ...bounds }, ref) => {
    const mapRef = useRef<naver.maps.Map>();
    const listenerRef = useRef<naver.maps.MapEventListener>();
    const markersMap = useRef<Map<string, naver.maps.Marker>>(new window.Map());
    const markerHandlersMap = useRef<Map<string, naver.maps.MapEventListener>>(
      new window.Map()
    );

    const callbackRef = useCallback((el: HTMLDivElement) => {
      if (el) {
        mapRef.current = new naver.maps.Map(el, {
          bounds,
          mapDataControl: false,
        });
        mapRef.current.fitBounds(bounds);

        listenerRef.current = naver.maps.Event.addListener(
          mapRef.current,
          'idle',
          onIdle
        );
        setMarkers(
          mapRef.current,
          markers,
          onMarkerClick,
          markersMap.current,
          markerHandlersMap.current
        );
      } else {
        listenerRef.current &&
          naver.maps.Event.removeListener(listenerRef.current);
        listenerRef.current = undefined;
        mapRef.current?.destroy();
        mapRef.current = undefined;
      }
    }, []); // eslint-disable-line

    useEffect(() => {
      if (!mapRef.current) {
        return;
      }
      setMarkers(
        mapRef.current,
        markers,
        onMarkerClick,
        markersMap.current,
        markerHandlersMap.current
      );

      return () => {
        if (!mapRef.current) {
          return;
        }

        removeMarkers(
          mapRef.current,
          markersMap.current,
          markerHandlersMap.current
        );
      };
    }, [onMarkerClick, markers]);

    useImperativeHandle(ref, () => mapRef.current);

    return <div id="map" ref={callbackRef} className={className}></div>;
  }
);
