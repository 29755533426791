import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './Popup.module.css';

export type PopupProps = {
  className?: string;
  children: ReactNode;
  onBackdropClick?: () => void;
  onCloseButtonClick?: () => void;
};

export const Popup = ({
  className,
  children,
  onBackdropClick,
  onCloseButtonClick,
  ...props
}: PopupProps) => {
  return (
    <div className={classNames(styles.root, className)} {...props}>
      <div className={styles.backdrop} onClick={onBackdropClick} />
      <div className={styles.content}>
        <div className={styles.contentWrapper}>
          <button
            type="button"
            onClick={onCloseButtonClick}
            className={classNames(styles.closeButton, 'btn btn-text')}
          >
            <i className="bi bi-x-lg"></i>
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};
